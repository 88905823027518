import React, { useContext, useEffect, useRef, useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import MainLayout from '../layouts/mainLayout'
import SEO from '../components/seo'
import { useWorkshops } from '../hooks/use-workshops'
import { SectionParagraph } from '../components/styled/section'
import scrollTo from '../scroll-to'
import { useLocation } from '@reach/router'
import { RegNoContext } from '../context/regno'
import styled from 'styled-components'
import { device } from '../components/device'
import FilterableWorkshopBox from '../components/filterableWorkshopBox'
import { generateWorkshopPagePrefix } from '../url-helpers'

const HolderWordboxses = styled.div`
  padding-top: -5rem;
  padding-bottom: 0.5rem;
`

const Flex = styled.div`
  @media only screen and ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
  }
`
const ColumnCenter = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5px;
  @media only screen and ${device.tabletMaxL} {
    grid-template-columns: 1fr;
    padding-bottom: 6rem;
  }

  @media only screen and ${device.laptop} {
    flex: 3;
    padding-bottom: 6rem;
    padding-left: 1rem;
  }
`

const HedingSelector = styled.div`
  max-width: 1348px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  padding-bottom: -3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1px;
  @media only screen and ${device.tabletMaxL} {
    padding-bottom: 1rem;
    margin-top: -9rem;
  }
  @media (max-width: 1036px) {
    padding-bottom: 3rem;
    margin-top: 5rem;
  }
`
const SectionsHolder = styled.div`
  margin-top: -7rem;
`
export const IndexSectionTitle = styled.h1`
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
  color: #333333;
  @media (min-width: 1441px) {
    margin-top: 5rem;
  }
  @media (max-width: 1023px) {
    margin-top: 1px;
  }
`
export const IndexSectionTitleTopHedign = styled.h1`
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 120rem;
  padding-top: 4rem;
  font-weight: 700;
  color: #333333;
  @media (min-width: 1441px) {
    margin-top: 12rem;
  }
  @media (max-width: 1023px) {
    margin-top: 1px;
  }
`

const IndexPage = () => {
  const {
    allCollectionPageHierarchy: { edges },
  } = useStaticQuery(graphql`
    query {
      allCollectionPageHierarchy {
        edges {
          node {
            name
            page_id
            parent_id
            level
            hidden
          }
        }
      }
    }
  `)

  const collectionPages = edges.map(({ node }) => node)

  const { setRegNo } = useContext(RegNoContext)
  const location = useLocation()
  let workshops = useWorkshops()
  const [pageHistory, setPageHistory] = useState([])
  // Denne vil alltid være den siste valgte siden i sidehistorikken
  //rerunn
  const selectedPage = pageHistory[pageHistory.length - 1]
  const canGoBack = pageHistory.length > 0

  const workshopsSection = useRef(null)
  const aboutSection = useRef(null)
  const sections = {
    '#oversikt': workshopsSection,
    '#abut': aboutSection,
  }
  useEffect(() => {
    if (location.hash && sections[location.hash]) {
      scrollTo(sections[location.hash])
    }
  }, [location.hash, sections])

  useEffect(() => {
    setRegNo('')
  }, [setRegNo])

  const goToWorkshop = async (workshops) => {
    const prefix = generateWorkshopPagePrefix(workshops)
    await navigate(`/${prefix}/${workshops.slug}/`)
  }

  return (
    <MainLayout
      collectionPages={collectionPages}
      onDropDownPageSelectionChange={(cp) => setPageHistory([...pageHistory, cp])}
    >
      <SEO title="Våre verksted | EU-kontroll" />

      <HedingSelector id="BilverkstedComp">
        <IndexSectionTitleTopHedign ref={workshopsSection}>
          Våre verksted
        </IndexSectionTitleTopHedign>

        <Flex>
          <ColumnCenter>
            <FilterableWorkshopBox
              selectedPage={selectedPage}
              collectionPages={collectionPages}
              workshops={workshops}
              goToWorkshop={goToWorkshop}
              canGoBack={canGoBack}
              goBack={() => {
                // Fjern alle sider som matcher gjeldene side. I dette tilfellet så sitter vi igjen
                // med forrige side
                setPageHistory(pageHistory.filter((hp) => hp.page_id !== selectedPage.page_id))
              }}
              onChange={(cp) => {
                // Legg siden til i side historikken så vi husker forrige
                // side i minnet
                setPageHistory([...pageHistory, cp])
              }}
            />
          </ColumnCenter>
        </Flex>
      </HedingSelector>

      <HolderWordboxses>
        <SectionsHolder>
          <div id="omoss"></div>
          <br></br>
          <br></br>
          <br></br>
          <IndexSectionTitle>Om BilXtra Verksted</IndexSectionTitle>

          <SectionParagraph>
            <h5>En del av konsernet AS Sørensen og Balchen AS</h5>
            <p>
              Sørensen og Balchen er en av de eldste og mest tradisjonsrike aktører i bilbransjen.
              ble etablert som handelsfirma og bilimportør i 1907 og har siden den tid vært en
              ledende distributør av slitedeler og rekvisita.
            </p>
            <p>
              I de senere år har Sørensen og Balchen bygget ut distribusjonsnettet i et høyt tempo.
              Gjennom oppkjøp og etablering av BilXtra-avdelinger har konsernet forsterket sin
              posisjon som Norges ledende distributør mot rekvisita- og verkstedsbransjen og BilXtra
              er i dag et av de mest kjente varemerker.
            </p>
          </SectionParagraph>
          <SectionParagraph>
            <h5>BilXtra Verksted</h5>
            <p>
              BilXtra verkstedkjede ble etablert våren 2009 og har på få år vokst seg til en av
              bransjens største kjeder med over 250 verksteder i 2017.
            </p>
            <p>
              Fremgangen kommer av helhetlige konsepter med presise systemer, god
              varetilgjengelighet, effektiv logistikk og leveranse, samt kursing og omfattende
              markedsføring.
            </p>
            <p>
              For å oppnå effektiv varestrøm og dermed gi kundene størst mulig konkurranseevne er
              det satset mye tid og ressurser på å utvikle integrerte bransjeløsninger for logistikk
              og verkstedsystemer.
            </p>
          </SectionParagraph>

          <SectionParagraph>
            <h5>Kvalitetsvarer</h5>
            <p>
              Sørensen og Balchen importerer og selger anerkjente kvalitetsprodukter produsert av de
              sterkeste leverandørene til bilindustrien. Vi operer utelukkende med OE-kvalitet eller
              Matching Quality. Dette er universelt aksepterte begrep som sier at våre deler er av
              minst like god kvalitet som original montert. Deler fra Sørensen og Balchen montert på
              ett av våre BilXtra-verksteder har 5 års garanti.
            </p>
          </SectionParagraph>
        </SectionsHolder>
      </HolderWordboxses>
      <HolderWordboxses>
        <IndexSectionTitle>Fordeler til deg som kunde</IndexSectionTitle>
        <SectionParagraph>
          <p>Hos BilXtra Verksted har vi flere tilbud som gir deg som kunde flere fordeler.</p>

          <h5>BilXtra Konto</h5>
          <p>Med BilXtra konto kan du dele opp betalingen uten ekstra kostnad.</p>

          <h5>BilXtra Mobilitetsgaranti</h5>
          <p>
            Med BilXtra Mobilitetsgaranti får du hjelp uansett tid og sted i hele Norden i et helt
            år. Når man tar en ny service hos BilXtra, blir garantien fornyet med 12 måneder.
          </p>
          <p>Dette gjelder:</p>
          <ul>
            <li>når bilen ikke vil starte eller ved driftsstans</li>
            <li>ved uhell – utforkjøring eller kollisjon</li>
            <li>når du har vært uheldig og låst deg ute av bilen</li>
            <li>når bilnøkkelen er ødelagt eller mistet</li>
            <li>når bilen har gått tom for drivstoff</li>
            <li>når det er fylt feil drivstoff på tanken</li>
            <li>når du har vært uheldig og punktert og trenger hjelp til å bytte hjul</li>
            <li>Leiebil i inntil 3 dager (Berging må opprettes)</li>
          </ul>
        </SectionParagraph>
      </HolderWordboxses>

      <HolderWordboxses>
        <IndexSectionTitle>Promeister Academy</IndexSectionTitle>
        <SectionParagraph>
          <p>
            Videreutdanning og kompetanse er noe vi setter meget høyt i BilXtra verksted. Vi kjører
            tekniker- utdanning med kompetansebevis etter avlagt prøve gjennom Promeister Academy.
          </p>
        </SectionParagraph>
      </HolderWordboxses>
    </MainLayout>
  )
}

export default IndexPage
